import React from 'react';
import CaseStudy from '../components/caseStudy';

const useCaseStudyResults = (results) => {
  return results.reduce((acc, data) => {
    const component = getResultComponent(data);
    return component ? [...acc, component] : acc;
  }, []);
};

const getResultComponent = (data) => {
  switch (data._type) {
    case 'caseStudyStat':
      return getStat(data);
    case 'caseStudyQuote':
      return getQuote(data);
    default:
      return null;
  }
};

const getStat = (data) => {
  try {
    const { value, unit, caption, key } = data;
    return <CaseStudy.Stat value={value} unit={unit} caption={caption} key={key} />;
  } catch (e) {
    logError(e, 'Stat');
    return false;
  }
};

const getQuote = (data) => {
  try {
    const { name, role, quote, key } = data;
    return (
      <CaseStudy.Quote name={name} role={role} key={key}>
        {quote}
      </CaseStudy.Quote>
    );
  } catch (e) {
    logError(e, 'Quote');
    return false;
  }
};

const logError = (e, resultType = 'Result') => {
  console.warn(`Unable to generate CaseStudy.${resultType}`);
  console.error(e);
};

export default useCaseStudyResults;
