import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import CaseStudy from '../components/caseStudy';
import CaseStudyTile from '../components/caseStudyTiles/tile';
import { Section } from '../components/containers';
import NextPrev from '../components/nextPrev';
import useCaseStudyResults from '../hooks/useCaseStudyResults';
import Pullquote from '../components/pullquote';

export default function Page({ data, pageContext }) {
  // ToDo: re-implement next/prev navigation

  const { next, previous } = pageContext;
  const { client, projectName, description, hero, results, gallery } = data.sanityCaseStudy;

  const resultComponents = useCaseStudyResults(results);

  const tileNext = next && <CaseStudyTile {...next} />;
  const tilePrevious = previous && <CaseStudyTile {...previous} />;

  return (
    <Layout template='casestudy'>
      <SEO title={`${client} | ${projectName}`} />

      <CaseStudy>
        <CaseStudy.Banner title={projectName} subtitle={client} image={hero.asset} />
        <Section>
          <CaseStudy.Story>{description}</CaseStudy.Story>
          <CaseStudy.Results>{resultComponents}</CaseStudy.Results>
        </Section>
        <CaseStudy.Gallery items={gallery} />
      </CaseStudy>

      <Section halign='center' key={Math.random()}>
        <Pullquote>More Work</Pullquote>

        <NextPrev>
          {tilePrevious}
          {tileNext}
        </NextPrev>
      </Section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($id: String!) {
    sanityCaseStudy(id: { eq: $id }) {
      ...caseStudy_Shared
      hero {
        asset {
          gatsbyImageData
          metadata {
            palette {
              main: darkVibrant {
                background
                foreground
              }
            }
          }
        }
      }

      description: _rawText

      gallery: items {
        ... on SanityContentBreak {
          ...contentBreak
        }
        ... on SanityContentImage {
          ...contentImage
        }
        ... on SanityVimeoEmbed {
          ...vimeoEmbed
        }
      }

      results {
        ... on SanityCaseStudyQuote {
          ...caseStudyQuote
        }
        ... on SanityCaseStudyStat {
          ...caseStudyStat
        }
      }
    }
  }
`;
