/**
 * Takes a one-dimensional arrayof objects
 * and converts into a 2 dimenaional array
 * using items with _type property of 'contentBreak' as
 * a delimiter.
 * EG: [ obj1,
 *       obj2,
 *       {_type: 'contentBreak'},
 *       obj3,
 *       {_type: 'contentBreak'},
 *       obj4,
 *       obj5 ]
 *   will be turned into:
 *       [[obj1,obj2],[obj3],[obj4,obj5]]
 *
 * Allows content managers to enter caseStudy items
 * as a single list within one panel on the CMS
 * and use a delimiter object to indicate how many
 * items on each row.
 */

// list of values for an object's _type key
// which will indicate it is a delimiter object
// not a content object
const delimTypes = ['cb', 'contentBreak'];

const useParseGalleryItemsAsGroups = (items) => {
  const result = [];
  items.forEach((item) => {
    if (isDelim(item)) {
      result.push([]);
    } else {
      result[0] = result[0] || [];
      result[result.length - 1].push(item);
    }
  });
  return result;
};

const isDelim = (item) => {
  return delimTypes.includes(item?._type);
};

export default useParseGalleryItemsAsGroups;
