const BlockContent = require('@sanity/block-content-to-react');

import React from 'react';
import { FullbleedHero } from '../hero';
import useParseGalleryItemsAsGroups from '../../hooks/useParseGalleryItemsAsGroups';
import { getGalleryitem } from './galleryUtil';
import { Grid } from '../containers';

const CaseStudy = ({ children }) => {
  return <>{children}</>;
};

const Banner = ({ subtitle, title, image }) => {
  return <FullbleedHero title={title} subtitle={subtitle} image={image} />;
};

const Results = ({ children }) => {
  return <ul data-token-casestudy-results>{children}</ul>;
};

const Gallery = ({ items }) => {
  const groups = useParseGalleryItemsAsGroups(items);

  return groups.map((group) => {
    const items = group.reduce((acc, item) => {
      const component = getGalleryitem(item);
      return component ? [...acc, component] : acc;
    }, []);

    return <GalleryGroup key={Math.random()}>{items}</GalleryGroup>;
  });
};

const GalleryGroup = ({ children }) => {
  const itemSpan = Math.floor(12 / children.length);
  const config = {
    colspans: {
      xs: 12,
      sm: itemSpan,
      md: itemSpan,
      lg: itemSpan,
      xl: itemSpan,
    },
  };
  return (
    <Grid valign='top' config={config} key={Math.random()}>
      {children}
    </Grid>
  );
};

const Story = ({ children }) => {
  return <BlockContent blocks={children} />;
};

const Stat = ({ value, unit, caption }) => {
  return (
    <li data-token-casestudy-stat>
      <p>
        <span>
          {value}
          <em>{unit}</em>
        </span>
        {caption}
      </p>
    </li>
  );
};
const Quote = ({ name, role, children }) => {
  return (
    <li data-token-casestudy-quote>
      <figure>
        <blockquote>{children}</blockquote>
        <figcaption>
          {name}
          <small>{role}</small>
        </figcaption>
      </figure>
    </li>
  );
};

CaseStudy.Story = Story;
CaseStudy.Gallery = Gallery;
CaseStudy.Banner = Banner;
CaseStudy.Results = Results;
CaseStudy.Stat = Stat;
CaseStudy.Quote = Quote;

export default CaseStudy;
