import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { VIMEO_BASE_URL } from '../../constants';
import ResponsiveVideo from '../responsiveVideo';

const getGalleryitem = (item) => {
  switch (item._type) {
    case 'contentImage':
      return <GatsbyImage image={getImage(item.asset)} key={item._key} alt='' />;
    case 'vimeoEmbed':
      return <ResponsiveVideo url={`${VIMEO_BASE_URL}/${item.vimeoId}`} key={item.vimeoId} />;
    default:
      return null;
  }
};

export { getGalleryitem };
